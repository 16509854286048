export const nextSkills = [
    "Tailwind CSS",
    "Next.js",
    "React Context",
    "Cypress",
    "JS classes",
    "TanStack Query",
    "Python w/ Django or Flask",
    "Dart",
];

export const developingSkills = [
    "TypeScript",
    "Node.js",
    "Express.js",
    "MongoBD",
    "React Native",
];

export const skills = [
    "React",
    "React Hooks",
    "React Router",
    "Redux(Toolkit)",
    "Redux - Saga",
    "Unit testing",
    "Mocha",
    "Jest and RTL",
    "Promises, Async / Await",
    "Working with API(fetch, axios)",
    "Error handling",
    "Immutability",
    "Semantic & accessible HTML",
    "Responsive Web Design",
    "JavaScript: ES6 +",
    "CSS Flexbox",
    "CSS Grid",
    "CSS BEM convention",
    "Markdown",
    "Git",
    "NPM",
    "GitHub Pull Requests & Review",
    "Client relations",
    "Multidisciplinary teamwork",
    "Scrum",
    "Trello",
];